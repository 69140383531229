import { svgIcon } from "@bitwarden/components";

export const PasswordManagerLogo = svgIcon`
<?xml version="1.0" encoding="UTF-8"?>
<svg id="Layer_2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 898.75 244.84">
  <defs>
    <style>
      .cls-1, .cls-2, .cls-3 {
        fill: #fff;
      }

      .cls-1, .cls-3, .cls-4 {
        fill-rule: evenodd;
      }

      .cls-5, .cls-4 {
        fill: #231f20;
      }

      .cls-3 {
        stroke: #fff;
        stroke-miterlimit: 10;
        stroke-width: 2px;
      }

      .cls-6 {
        stroke-linecap: square;
      }

      .cls-6, .cls-7, .cls-8 {
        fill: none;
        stroke: #e6c196;
        stroke-miterlimit: 1.5;
      }

      .cls-6, .cls-8 {
        stroke-width: .1px;
      }

      .cls-7 {
        stroke-width: .05px;
      }
    </style>
  </defs>
  <g id="Layer_1-2" data-name="Layer_1">
    <g>
      <g>
        <g>
          <path class="cls-3" d="M145.31,30.04c48.35,15.26,75.21,66.91,59.95,115.26-15.26,48.35-66.91,75.21-115.26,59.95-48.35-15.26-75.21-66.91-59.95-115.26C45.31,41.64,96.95,14.78,145.31,30.04ZM145.73,28.71c49.09,15.5,76.36,67.93,60.86,117.02-15.5,49.09-67.93,76.36-117.02,60.86-49.09-15.5-76.36-67.93-60.86-117.02C44.21,40.48,96.64,13.21,145.73,28.71Z"/>
          <polygon class="cls-1" points="27.06 164.6 113.19 109.05 208.24 70.69 122.11 126.25 27.06 164.6"/>
          <polygon class="cls-1" points="70.7 27.05 126.19 113.22 164.6 208.24 109.11 122.07 70.7 27.05"/>
          <polygon class="cls-4" points="80.12 45.25 103.11 101.67 108.04 99.11 80.12 45.25"/>
          <polygon class="cls-4" points="155.44 189.89 132.45 133.47 127.52 136.03 155.44 189.89"/>
          <polygon class="cls-4" points="189.87 79.95 133.45 102.93 136 107.87 189.87 79.95"/>
          <polygon class="cls-4" points="45.33 155.2 101.76 132.22 99.2 127.29 45.33 155.2"/>
          <polygon class="cls-1" points="0 80.55 123.13 100.25 235.3 154.74 112.16 135.04 0 80.55"/>
          <polygon class="cls-1" points="154.74 0 135.05 123.13 80.56 235.29 100.25 112.16 154.74 0"/>
          <polygon class="cls-4" points="150.43 11.51 117.22 116.82 103.48 112.49 150.43 11.51"/>
          <polygon class="cls-4" points="223.8 150.43 118.47 117.22 122.81 103.47 223.8 150.43"/>
          <polygon class="cls-4" points="84.87 223.8 118.08 118.47 131.82 122.8 84.87 223.8"/>
          <polygon class="cls-4" points="11.5 84.87 116.82 118.08 112.49 131.82 11.5 84.87"/>
          <polygon class="cls-1" points="120.98 118.01 103.48 112.49 109 94.98 120.98 118.01"/>
          <polygon class="cls-6" points="120.98 118.01 103.48 112.49 109 94.98 120.98 118.01"/>
          <polygon class="cls-7" points="117.49 120.35 123.01 102.85 140.49 108.36 117.49 120.35"/>
          <polygon class="cls-1" points="117.49 120.35 123.01 102.85 140.49 108.36 117.49 120.35"/>
          <polygon class="cls-1" points="114.59 117.37 132.45 123 126.82 140.85 114.59 117.37"/>
          <polygon class="cls-8" points="114.59 117.37 132.45 123 126.82 140.85 114.59 117.37"/>
          <polygon class="cls-1" points="117.91 114.64 112.29 132.45 94.51 126.84 117.91 114.64"/>
          <polygon class="cls-6" points="117.91 114.64 112.29 132.45 94.51 126.84 117.91 114.64"/>
        </g>
        <g>
          <circle class="cls-2" cx="117.65" cy="117.65" r="66.81" transform="translate(-48.73 117.65) rotate(-45)"/>
          <g>
            <path class="cls-5" d="M104.54,162.27c.5-.06,42.06-4.65,59.29-55.03,0-.02-.03-.07-.03-.07-35.84-35.38-78.01-24.59-78.01-24.59,0,.03-.02.06-.03.08-14.7,50.94,17.02,79.06,17.02,79.06l.75.67,1-.12Z"/>
            <path class="cls-2" d="M127.38,115.26c-1.42,4.51-6.23,7.02-10.74,5.59-4.51-1.42-7.02-6.23-5.59-10.75,1.42-4.51,6.23-7.02,10.75-5.59,4.51,1.42,7.02,6.23,5.59,10.75Z"/>
            <path class="cls-2" d="M115.57,135.23c-.56,1.77-2.44,2.75-4.22,2.19h0c-1.77-.56-2.75-2.45-2.2-4.22l6.07-19.24c.56-1.77,2.45-2.75,4.22-2.19h0c1.77.56,2.75,2.44,2.19,4.22l-6.06,19.24Z"/>
          </g>
        </g>
      </g>
      <g>
        <path class="cls-2" d="M261.72,81.81c5.53-.94,13.29-1.65,24.23-1.65s18.94,2.12,24.23,6.35c5.18,4,8.47,10.59,8.47,18.35s-2.47,14.47-7.29,18.82c-6.12,5.65-15.17,8.35-25.76,8.35-2.35,0-4.47-.12-6.12-.47v28.46h-17.76v-78.21ZM279.48,117.8c1.53.35,3.29.47,6,.47,9.53,0,15.41-4.82,15.41-12.82,0-7.29-5.06-11.64-14.11-11.64-3.53,0-6,.24-7.29.59v23.41Z"/>
        <path class="cls-2" d="M375.23,146.15c0,5.41.24,10.7.94,13.88h-16.11l-1.06-5.76h-.35c-3.76,4.59-9.64,7.06-16.47,7.06-11.64,0-18.58-8.47-18.58-17.64,0-14.94,13.41-21.99,33.76-21.99v-.71c0-3.18-1.65-7.53-10.47-7.53-5.88,0-12.11,2-15.88,4.35l-3.29-11.53c4-2.23,11.88-5.17,22.35-5.17,19.17,0,25.17,11.29,25.17,24.93v20.11ZM357.94,132.86c-9.41,0-16.7,2.23-16.7,9.06,0,4.59,3.06,6.82,7.06,6.82,4.35,0,8.12-2.94,9.29-6.59.24-.94.35-2,.35-3.06v-6.23Z"/>
        <path class="cls-2" d="M389.34,144.5c3.29,2,10.11,4.23,15.41,4.23s7.64-1.76,7.64-4.7-1.76-4.47-8.35-6.7c-12-4-16.58-10.47-16.47-17.29,0-10.82,9.17-18.94,23.41-18.94,6.7,0,12.58,1.65,16.11,3.41l-3.06,12.35c-2.59-1.41-7.65-3.29-12.47-3.29-4.35,0-6.82,1.76-6.82,4.59s2.23,4.23,9.29,6.7c10.94,3.76,15.41,9.41,15.53,17.76,0,10.82-8.35,18.7-24.82,18.7-7.53,0-14.23-1.76-18.58-4.12l3.18-12.7Z"/>
        <path class="cls-2" d="M440.39,144.5c3.29,2,10.12,4.23,15.41,4.23s7.64-1.76,7.64-4.7-1.76-4.47-8.35-6.7c-12-4-16.58-10.47-16.47-17.29,0-10.82,9.17-18.94,23.41-18.94,6.7,0,12.58,1.65,16.11,3.41l-3.06,12.35c-2.59-1.41-7.64-3.29-12.47-3.29-4.35,0-6.82,1.76-6.82,4.59s2.23,4.23,9.29,6.7c10.94,3.76,15.41,9.41,15.53,17.76,0,10.82-8.35,18.7-24.82,18.7-7.53,0-14.23-1.76-18.58-4.12l3.18-12.7Z"/>
        <path class="cls-2" d="M539.42,146.15c0,5.41.24,10.7.94,13.88h-16.11l-1.06-5.76h-.35c-3.76,4.59-9.64,7.06-16.47,7.06-11.64,0-18.58-8.47-18.58-17.64,0-14.94,13.41-21.99,33.75-21.99v-.71c0-3.18-1.65-7.53-10.47-7.53-5.88,0-12.11,2-15.88,4.35l-3.29-11.53c4-2.23,11.88-5.17,22.35-5.17,19.17,0,25.17,11.29,25.17,24.93v20.11ZM522.13,132.86c-9.41,0-16.7,2.23-16.7,9.06,0,4.59,3.06,6.82,7.06,6.82,4.35,0,8.12-2.94,9.29-6.59.24-.94.35-2,.35-3.06v-6.23Z"/>
        <path class="cls-2" d="M608.11,151.68c0,11.06-2.23,20.11-8.82,25.88-6.23,5.41-14.82,7.06-23.29,7.06-7.65,0-15.53-1.53-20.7-4.35l3.53-13.64c3.65,2.12,9.88,4.35,16.7,4.35,8.47,0,14.94-4.47,14.94-15.17v-3.65h-.24c-3.41,4.7-8.94,7.41-15.53,7.41-14.23,0-24.35-11.53-24.35-27.99,0-18.58,11.88-30.34,26.11-30.34,7.88,0,12.82,3.41,15.76,8.23h.24l.59-6.94h15.53c-.24,3.76-.47,8.59-.47,17.17v31.99ZM590.24,125.92c0-1.06-.12-2.23-.35-3.29-1.29-4.71-4.7-7.88-9.64-7.88-6.59,0-11.76,5.88-11.76,16.35,0,8.59,4.23,15.29,11.64,15.29,4.71,0,8.35-3.06,9.53-7.17.47-1.41.59-3.29.59-4.82v-8.47Z"/>
        <path class="cls-2" d="M636.23,136.97c.47,7.41,7.88,10.94,16.23,10.94,6.12,0,11.06-.82,15.88-2.47l2.35,12.11c-5.88,2.47-13.05,3.65-20.82,3.65-19.52,0-30.7-11.29-30.7-29.29,0-14.58,9.06-30.7,29.05-30.7,18.58,0,25.64,14.47,25.64,28.7,0,3.06-.35,5.76-.59,7.06h-37.05ZM657.28,124.74c0-4.35-1.88-11.64-10.11-11.64-7.53,0-10.59,6.94-11.06,11.64h21.17Z"/>
        <path class="cls-2" d="M697.98,160.03l-18.82-79.27h19.17l6,32.58c1.76,9.53,3.41,19.76,4.71,27.76h.24c1.29-8.59,3.17-18.11,5.17-27.99l6.7-32.34h19.05l6.35,33.4c1.76,9.29,3.06,17.76,4.23,26.58h.24c1.18-8.82,2.94-18.11,4.59-27.64l6.47-32.34h18.23l-20.46,79.27h-19.41l-6.7-34.11c-1.53-8-2.82-15.41-3.76-24.46h-.24c-1.41,8.94-2.71,16.47-4.59,24.46l-7.53,34.11h-19.64Z"/>
        <path class="cls-2" d="M831.95,146.15c0,5.41.24,10.7.94,13.88h-16.11l-1.06-5.76h-.35c-3.76,4.59-9.64,7.06-16.47,7.06-11.64,0-18.58-8.47-18.58-17.64,0-14.94,13.41-21.99,33.75-21.99v-.71c0-3.18-1.65-7.53-10.47-7.53-5.88,0-12.11,2-15.88,4.35l-3.29-11.53c4-2.23,11.88-5.17,22.35-5.17,19.17,0,25.17,11.29,25.17,24.93v20.11ZM814.66,132.86c-9.41,0-16.7,2.23-16.7,9.06,0,4.59,3.06,6.82,7.06,6.82,4.35,0,8.12-2.94,9.29-6.59.24-.94.35-2,.35-3.06v-6.23Z"/>
        <path class="cls-2" d="M858.18,102.51l8.59,28.34c.94,3.29,2.12,7.65,2.82,10.7h.47c.71-3.18,1.65-7.41,2.47-10.82l7.17-28.23h19.05l-13.41,37.87c-8.23,22.93-13.76,31.99-20.23,37.75-6.12,5.41-12.7,7.41-17.17,7.88l-3.88-15.05c2.23-.47,5.06-1.53,7.76-3.06,2.71-1.41,5.76-4.35,7.41-7.17.59-.94.94-1.88.94-2.59s-.12-1.53-.71-2.94l-21.17-52.69h19.88Z"/>
      </g>
    </g>
    <g>
      <path class="cls-2" d="M462.63,195.84c2.35-.39,5.43-.73,9.35-.73,4.82,0,8.34,1.12,10.58,3.14,2.07,1.79,3.3,4.54,3.3,7.9s-1.01,6.1-2.91,8.06c-2.58,2.74-6.78,4.14-11.54,4.14-1.46,0-2.8-.06-3.92-.34v15.12h-4.87v-37.3ZM467.5,214.04c1.06.28,2.41.39,4.03.39,5.88,0,9.46-2.86,9.46-8.06s-3.53-7.39-8.9-7.39c-2.13,0-3.75.17-4.59.39v14.67Z"/>
      <path class="cls-2" d="M505.81,233.14l-.39-3.42h-.17c-1.51,2.13-4.42,4.03-8.29,4.03-5.49,0-8.29-3.86-8.29-7.78,0-6.55,5.82-10.14,16.3-10.08v-.56c0-2.24-.62-6.27-6.16-6.27-2.52,0-5.15.78-7.06,2.02l-1.12-3.25c2.24-1.46,5.49-2.41,8.9-2.41,8.29,0,10.3,5.66,10.3,11.09v10.14c0,2.35.11,4.65.45,6.5h-4.48ZM505.08,219.3c-5.38-.11-11.48.84-11.48,6.1,0,3.19,2.13,4.7,4.65,4.7,3.53,0,5.77-2.24,6.55-4.54.17-.5.28-1.06.28-1.57v-4.7Z"/>
      <path class="cls-2" d="M517.12,228.1c1.46.95,4.03,1.96,6.5,1.96,3.58,0,5.26-1.79,5.26-4.03,0-2.35-1.4-3.64-5.04-4.98-4.87-1.74-7.17-4.42-7.17-7.67,0-4.37,3.53-7.95,9.35-7.95,2.74,0,5.15.78,6.66,1.68l-1.23,3.58c-1.06-.67-3.02-1.57-5.54-1.57-2.91,0-4.54,1.68-4.54,3.7,0,2.24,1.62,3.25,5.15,4.59,4.7,1.79,7.11,4.14,7.11,8.18,0,4.76-3.7,8.12-10.14,8.12-2.97,0-5.71-.73-7.62-1.85l1.23-3.75Z"/>
      <path class="cls-2" d="M539.29,228.1c1.46.95,4.03,1.96,6.5,1.96,3.58,0,5.26-1.79,5.26-4.03,0-2.35-1.4-3.64-5.04-4.98-4.87-1.74-7.17-4.42-7.17-7.67,0-4.37,3.53-7.95,9.35-7.95,2.74,0,5.15.78,6.66,1.68l-1.23,3.58c-1.06-.67-3.02-1.57-5.54-1.57-2.91,0-4.54,1.68-4.54,3.7,0,2.24,1.62,3.25,5.15,4.59,4.7,1.79,7.11,4.14,7.11,8.18,0,4.76-3.7,8.12-10.14,8.12-2.97,0-5.71-.73-7.62-1.85l1.23-3.75Z"/>
      <path class="cls-2" d="M564.1,206.03l3.58,13.78c.78,3.02,1.51,5.82,2.02,8.62h.17c.62-2.74,1.51-5.66,2.41-8.57l4.42-13.83h4.14l4.2,13.55c1.01,3.25,1.79,6.1,2.41,8.85h.17c.45-2.74,1.18-5.6,2.07-8.79l3.86-13.61h4.87l-8.74,27.1h-4.48l-4.14-12.94c-.95-3.02-1.74-5.71-2.41-8.9h-.11c-.67,3.25-1.51,6.05-2.46,8.96l-4.37,12.88h-4.48l-8.18-27.1h5.04Z"/>
      <path class="cls-2" d="M627.33,219.36c0,10.02-6.94,14.39-13.5,14.39-7.34,0-12.99-5.38-12.99-13.94,0-9.07,5.94-14.39,13.44-14.39s13.05,5.66,13.05,13.94ZM605.82,219.64c0,5.94,3.42,10.42,8.23,10.42s8.23-4.42,8.23-10.53c0-4.59-2.3-10.42-8.12-10.42s-8.34,5.38-8.34,10.53Z"/>
      <path class="cls-2" d="M633.54,214.49c0-3.19-.06-5.94-.22-8.46h4.31l.17,5.32h.22c1.23-3.64,4.2-5.94,7.5-5.94.56,0,.95.06,1.4.17v4.65c-.5-.11-1.01-.17-1.68-.17-3.47,0-5.94,2.63-6.61,6.33-.11.67-.22,1.46-.22,2.3v14.45h-4.87v-18.65Z"/>
      <path class="cls-2" d="M674.7,193.38v32.76c0,2.41.06,5.15.22,7h-4.42l-.22-4.7h-.11c-1.51,3.02-4.82,5.32-9.24,5.32-6.55,0-11.59-5.54-11.59-13.78-.06-9.02,5.54-14.56,12.15-14.56,4.14,0,6.94,1.96,8.18,4.14h.11v-16.18h4.93ZM669.77,217.06c0-.62-.06-1.46-.22-2.07-.73-3.14-3.42-5.71-7.11-5.71-5.1,0-8.12,4.48-8.12,10.47,0,5.49,2.69,10.02,8.01,10.02,3.3,0,6.33-2.18,7.22-5.88.17-.67.22-1.34.22-2.13v-4.7Z"/>
      <path class="cls-2" d="M726.72,216.56c-.28-5.26-.62-11.59-.56-16.3h-.17c-1.29,4.42-2.86,9.13-4.76,14.34l-6.66,18.31h-3.7l-6.1-17.98c-1.79-5.32-3.3-10.19-4.37-14.67h-.11c-.11,4.7-.39,11.03-.73,16.69l-1.01,16.18h-4.65l2.63-37.74h6.22l6.44,18.26c1.57,4.65,2.86,8.79,3.81,12.71h.17c.95-3.81,2.3-7.95,3.98-12.71l6.72-18.26h6.22l2.35,37.74h-4.76l-.95-16.58Z"/>
      <path class="cls-2" d="M755.12,233.14l-.39-3.42h-.17c-1.51,2.13-4.42,4.03-8.29,4.03-5.49,0-8.29-3.86-8.29-7.78,0-6.55,5.82-10.14,16.3-10.08v-.56c0-2.24-.62-6.27-6.16-6.27-2.52,0-5.15.78-7.06,2.02l-1.12-3.25c2.24-1.46,5.49-2.41,8.9-2.41,8.29,0,10.3,5.66,10.3,11.09v10.14c0,2.35.11,4.65.45,6.5h-4.48ZM754.39,219.3c-5.38-.11-11.48.84-11.48,6.1,0,3.19,2.13,4.7,4.65,4.7,3.53,0,5.77-2.24,6.55-4.54.17-.5.28-1.06.28-1.57v-4.7Z"/>
      <path class="cls-2" d="M767.1,213.37c0-2.8-.06-5.1-.22-7.34h4.37l.28,4.48h.11c1.34-2.58,4.48-5.1,8.96-5.1,3.75,0,9.58,2.24,9.58,11.54v16.18h-4.93v-15.62c0-4.37-1.62-8.01-6.27-8.01-3.25,0-5.77,2.3-6.61,5.04-.22.62-.34,1.46-.34,2.3v16.3h-4.93v-19.77Z"/>
      <path class="cls-2" d="M813.19,233.14l-.39-3.42h-.17c-1.51,2.13-4.42,4.03-8.29,4.03-5.49,0-8.29-3.86-8.29-7.78,0-6.55,5.82-10.14,16.3-10.08v-.56c0-2.24-.62-6.27-6.16-6.27-2.52,0-5.15.78-7.06,2.02l-1.12-3.25c2.24-1.46,5.49-2.41,8.9-2.41,8.29,0,10.3,5.66,10.3,11.09v10.14c0,2.35.11,4.65.45,6.5h-4.48ZM812.46,219.3c-5.38-.11-11.48.84-11.48,6.1,0,3.19,2.13,4.7,4.65,4.7,3.53,0,5.77-2.24,6.55-4.54.17-.5.28-1.06.28-1.57v-4.7Z"/>
      <path class="cls-2" d="M848.52,206.03c-.11,1.96-.22,4.14-.22,7.45v15.74c0,6.22-1.23,10.02-3.86,12.38-2.63,2.46-6.44,3.25-9.86,3.25s-6.83-.78-9.02-2.24l1.23-3.75c1.79,1.12,4.59,2.13,7.95,2.13,5.04,0,8.74-2.63,8.74-9.46v-3.02h-.11c-1.51,2.52-4.42,4.54-8.62,4.54-6.72,0-11.54-5.71-11.54-13.22,0-9.18,5.99-14.39,12.21-14.39,4.7,0,7.28,2.46,8.46,4.7h.11l.22-4.09h4.31ZM843.43,216.73c0-.84-.06-1.57-.28-2.24-.9-2.86-3.3-5.21-6.89-5.21-4.7,0-8.06,3.98-8.06,10.25,0,5.32,2.69,9.74,8.01,9.74,3.02,0,5.77-1.9,6.83-5.04.28-.84.39-1.79.39-2.63v-4.87Z"/>
      <path class="cls-2" d="M859.22,220.48c.11,6.66,4.37,9.41,9.3,9.41,3.53,0,5.66-.62,7.5-1.4l.84,3.53c-1.74.78-4.7,1.68-9.02,1.68-8.34,0-13.33-5.49-13.33-13.66s4.82-14.62,12.71-14.62c8.85,0,11.2,7.78,11.2,12.77,0,1.01-.11,1.79-.17,2.3h-19.04ZM873.67,216.95c.06-3.14-1.29-8.01-6.83-8.01-4.98,0-7.17,4.59-7.56,8.01h14.39Z"/>
      <path class="cls-2" d="M884.53,214.49c0-3.19-.06-5.94-.22-8.46h4.31l.17,5.32h.22c1.23-3.64,4.2-5.94,7.5-5.94.56,0,.95.06,1.4.17v4.65c-.5-.11-1.01-.17-1.68-.17-3.47,0-5.94,2.63-6.61,6.33-.11.67-.22,1.46-.22,2.3v14.45h-4.87v-18.65Z"/>
    </g>
  </g>
</svg>`;
